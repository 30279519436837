<template>
  <div id="cdash" class="camp-dashboard template-1">
    <page-header class="mx-lg">
      <h1>{{ translations.tcDashboard }}</h1>
    </page-header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcCurrentHome" :i18n="translations.components">
      </camp-selector>
      <!-- / Camp Selector -->
      <page-body class="mx-lg">
        <section class="section-1 bg-block" v-if="displayrefreshhappening">
          <div class="simple-table">
            <table class="table b-table table-striped table-hover table-borderless">
              <thead>
                <tr>
                  <th class>{{ translations.tcDailyDataRefreshInProgress }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </section>
        <section class="section-1 bg-block" v-if="!displayrefreshhappening">
          <div class="d-flex" v-if="chartdataloaded && !!translations.components">
            <div class="chart-receipts-container">
              <ReceiptsChart :data="chartreceipts" :i18n="translations.components"></ReceiptsChart>
            </div>
            <div class="chart-placements-container">
              <PlacementsChart :data="chartplacements" :i18n="translations.components"></PlacementsChart>
            </div>
          </div>
        </section>
        <section class="section-2 bg-block" v-if="!displayrefreshhappening">
          <div class="simple-table">
            <b-table-simple hover small caption-top responsive style="margin-bottom: 0;"
              class="table b-table table-striped table-hover table-borderless">
              <b-thead>
                <b-tr>
                  <th class>{{ translations.tcCampScriptureReceipts }}</th>
                  <th class="month-width">{{ translations.tcMonth }}</th>
                  <th class="actual-ytd-width">{{ translations.tcActualYTD }}</th>
                  <th class>{{ translations.tcProRataGoal }}</th>
                  <th class>{{ translations.tcAnnualGoal }}</th>
                </b-tr>
              </b-thead>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive style="margin: 0;"
              class="table b-table table-striped table-hover table-borderless">
              <b-tbody
                v-for="(row, guidOCCategoryID) in receipts.filter((item) => { return item.TokenValue !== 'Barnabas Fund' })"
                v-bind:key="'SR' + guidOCCategoryID">
                <b-tr>
                  <b-td>
                    <span v-if="!!translations.components"><b>{{
        getRowTextType(row.TokenValue, row.guidOCCategoryID, 'scripture-receipts')
      }}</b></span>
                  </b-td>
                  <b-td class="month-width">
                    <span><b>{{ formatDollars(row.MonthlyAmount, false) }}</b></span>
                  </b-td>
                  <b-td class="actual-ytd-width">
                    <span><b>{{ formatDollars(row.CampActual, false) }}</b></span>
                  </b-td>
                  <b-td>
                    <span><b>{{ formatDollars(row.MonthlyCatTotal, false) }}</b></span>
                  </b-td>
                  <b-td>
                    <span><b>{{ formatDollars(row.CampGoal, false) }}</b></span>
                  </b-td>
                </b-tr>

                <b-tr v-for="(drow, idx) in row.Details" :key="idx">
                  <b-td>
                    <i style="padding-left: 20px;">{{ drow.label }}</i>
                  </b-td>
                  <b-td>
                    <i>{{ formatDollars(drow.data.month, false) }}</i>
                  </b-td>
                  <b-td class="actual-ytd-width">
                    <i>{{ formatDollars(drow.data.ytd, false) }}</i>
                  </b-td>
                  <b-td>
                    <i v-if="drow.label === 'Camp Scripture Fund'">{{ formatDollars(0, false) }}</i>
                  </b-td>
                  <b-td>
                    <i v-if="drow.label === 'Camp Scripture Fund'">{{ formatDollars(0, false) }}</i>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive
              class="table b-table table-striped table-hover table-borderless">
              <b-tbody>
                <b-tr class="table-total-row">
                  <b-td>
                    <span>All Scripture {{ translations.tcTotal }}</span>
                  </b-td>
                  <b-td class="month-width">
                    <span>{{ formatDollars(calcTotal('ReceiptMonthlyAmountWithOutBarnabas'), false) }}</span>
                  </b-td>
                  <b-td class="actual-ytd-width">
                    <span>{{ formatDollars(calcTotal('ReceiptCampActualWithOutBarnabas'), false) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatDollars(calcTotal('ReceiptMonthlyCatTotalWithOutBarnabas'), false) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatDollars(calcTotal('ReceiptCampGoalWithOutBarnabas'), false) }}</span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive style="margin: 0;"
              class="table b-table table-striped table-hover table-borderless">
              <b-tbody
                v-for="(row, guidOCCategoryID) in receipts.filter((item) => { return item.TokenValue === 'Barnabas Fund' })"
                v-bind:key="'SR' + guidOCCategoryID">
                <b-tr>
                  <b-td>
                    <span v-if="!!translations.components"><b>{{
        getRowTextType(row.TokenValue, row.guidOCCategoryID, 'scripture-receipts')
      }}</b></span>
                  </b-td>
                  <b-td class="month-width">
                    <span><b>{{ formatDollars(row.MonthlyAmount, false) }}</b></span>
                  </b-td>
                  <b-td class="actual-ytd-width">
                    <span><b>{{ formatDollars(row.CampActual, false) }}</b></span>
                  </b-td>
                  <b-td>
                    <span><b>{{ formatDollars(row.MonthlyCatTotal, false) }}</b></span>
                  </b-td>
                  <b-td>
                    <span><b>{{ formatDollars(row.CampGoal, false) }}</b></span>
                  </b-td>
                </b-tr>

                <b-tr v-for="(drow, idx) in row.Details" :key="idx">
                  <b-td>
                    <i style="padding-left: 20px;">{{ drow.label }}</i>
                  </b-td>
                  <b-td>
                    <i>{{ formatDollars(drow.data.month, false) }}</i>
                  </b-td>
                  <b-td class="actual-ytd-width">
                    <i>{{ formatDollars(drow.data.ytd, false) }}</i>
                  </b-td>
                  <b-td>
                    <i v-if="drow.label === 'Camp Scripture Fund'">{{ formatDollars(0, false) }}</i>
                  </b-td>
                  <b-td>
                    <i v-if="drow.label === 'Camp Scripture Fund'">{{ formatDollars(0, false) }}</i>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive
              class="table b-table table-striped table-hover table-borderless">
              <b-tbody>
                <b-tr class="table-total-row">
                  <b-td>
                    <span>Receipts Grand {{ translations.tcTotal }}</span>
                  </b-td>
                  <b-td class="month-width">
                    <span>{{ formatDollars(calcTotal('ReceiptMonthlyAmount'), false) }}</span>
                  </b-td>
                  <b-td class="actual-ytd-width">
                    <span>{{ formatDollars(calcTotal('ReceiptCampActual'), false) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatDollars(calcTotal('ReceiptMonthlyCatTotal'), false) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatDollars(calcTotal('ReceiptCampGoal'), false) }}</span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive
              class="table b-table table-striped table-hover table-borderless">
              <b-thead>
                <b-tr>
                  <th class>{{ translations.tcCampScripturePlacements }}</th>
                  <th class>{{ translations.tcMonth }}</th>
                  <th>{{ translations.tcActualYTD }}</th>
                  <th class>{{ translations.tcProRataGoal }}</th>
                  <th class>{{ translations.tcAnnualGoal }}</th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr class="table-total-row">
                  <b-td>
                    <span>{{ translations.tcSubTotalGideon }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsMonthlyAmountGid')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsCampActualGid')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsMonthlyCatTotalGid')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsCampGoalGid')) }}</span>
                  </b-td>
                </b-tr>
                <b-tr v-for="(row, guidOCCategoryID) in filteredplacements('G')" v-bind:key="'G' + guidOCCategoryID">
                  <b-td style="padding-left: 20px;">
                    <i v-if="!!translations.components">{{
        getRowTextType(row.TokenValue, row.guidOCCategoryID, 'scripture-placements')
      }}</i>
                  </b-td>
                  <b-td>
                    <i>{{ formatNumber(row.MonthlyAmount) }}</i>
                  </b-td>
                  <b-td>
                    <i>{{ formatNumber(row.CampActual) }}</i>
                  </b-td>
                  <b-td>
                    <i>{{ formatNumber(row.MonthlyCatTotal) }}</i>
                  </b-td>
                  <b-td>
                    <i>{{ formatNumber(row.CampGoal) }}</i>
                  </b-td>
                </b-tr>
                <b-tr class="table-total-row">
                  <b-td>
                    <span>{{ translations.tcSubTotalAuxiliary }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsMonthlyAmountAux')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsCampActualAux')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsMonthlyCatTotalAux')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsCampGoalAux')) }}</span>
                  </b-td>
                </b-tr>
                <b-tr v-for="(row, guidOCCategoryID) in filteredplacements('A')" v-bind:key="'A' + guidOCCategoryID">
                  <b-td style="padding-left: 20px;">
                    <i v-if="!!translations.components">{{
        getRowTextType(row.TokenValue, row.guidOCCategoryID, 'scripture-placements')
      }}</i>
                  </b-td>
                  <b-td>
                    <i>{{ formatNumber(row.MonthlyAmount) }}</i>
                  </b-td>
                  <b-td>
                    <i>{{ formatNumber(row.CampActual) }}</i>
                  </b-td>
                  <b-td>
                    <i>{{ formatNumber(row.MonthlyCatTotal) }}</i>
                  </b-td>
                  <b-td>
                    <i>{{ formatNumber(row.CampGoal) }}</i>
                  </b-td>
                </b-tr>
                <b-tr class="table-total-row">
                  <b-td>
                    <span>{{ translations.tcTotal }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsMonthlyAmount')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsCampActual')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsMonthlyCatTotal')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PlacementsCampGoal')) }}</span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive
              class="table b-table table-striped table-hover table-borderless">
              <b-thead>
                <b-tr>
                  <th class>{{ translations.tcPersonalWorkersTestaments }}</th>
                  <th class>{{ translations.tcMonth }}</th>
                  <th class>{{ translations.tcActualYTD }}</th>
                  <th class>{{ translations.tcProRataGoal }}</th>
                  <th class>{{ translations.tcAnnualGoal }}</th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(row, guidOCCategoryID) in pwts" v-bind:key="guidOCCategoryID">
                  <b-td>
                    <span v-if="!!translations.components">{{ getRowTextType(row.TokenValue, row.guidOCCategoryID,
        'scripture-placements')
                      }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(row.MonthlyAmount) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(row.CampActual) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(row.MonthlyCatTotal) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(row.CampGoal) }}</span>
                  </b-td>
                </b-tr>
                <b-tr class="table-total-row">
                  <b-td>
                    <span>{{ translations.tcTotal }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PwtsMonthlyAmount')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PwtsCampActual')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PwtsMonthlyCatTotal')) }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ formatNumber(calcTotal('PwtsCampGoal')) }}</span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive
              class="table b-table table-striped table-hover table-borderless">
              <b-thead>
                <b-tr>
                  <th class>{{ translations.tcMembership }}</th>
                  <th style="width:113px">{{ translations.tcGideon }}</th>
                  <th style="width:174px">{{ translations.tcAuxiliary }}</th>
                  <th colspan="2" style="width:192px"></th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(mbr, ix) in member_data" :key="ix" class="table-total-row">
                  <b-td>
                    <span>{{ mbr.nbr0 }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ mbr.nbr1 }}</span>
                  </b-td>
                  <b-td>
                    <span>{{ mbr.nbr2 }}</span>
                  </b-td>
                  <b-td colspan="2">
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive
              class="table b-table table-striped table-hover table-borderless">
              <b-thead>
                <b-tr>
                  <th class>{{ translations.tcOtherCampKeyMeasurements }}</th>
                  <th class>{{ translations.tcMonth }}</th>
                  <th class>{{ translations.tcActualYTD }}</th>
                  <th class>{{ translations.tcProRataGoal }}</th>
                  <th class>{{ translations.tcAnnualGoal }}</th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    <b>{{ translations.tcChurchesWithPresentations }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].RcptMTD_RptdSvcs }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].RcptYTD_RptdSvcs }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].Church_Actual }} ({{
        formatPercent(
          otherkeymeasures[0].RcptYTD_RptdSvcs,
          otherkeymeasures[0].Church_Actual,
          0,
          false
        )
      }})</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].Church_Goal }} ({{
        formatPercent(otherkeymeasures[0].RcptYTD_RptdSvcs, otherkeymeasures[0].Church_Goal, 0, false)
      }})</b>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <b>{{ translations.tcGideonCardProgramDonors }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].Donors_M }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].Donors_Y }}</b>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <b>{{ translations.tcGideonCard }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].Video_GID_M }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].Video_GID_Y }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].GideonExpression_Actual }} ({{
        formatPercent(
          otherkeymeasures[0].Video_GID_Y,
          otherkeymeasures[0].GideonExpression_Actual,
          0,
          false
        )
      }})</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].GideonExpression_Goal }} ({{
        formatPercent(
          otherkeymeasures[0].Video_GID_Y,
          otherkeymeasures[0].GideonExpression_Goal,
          0,
          false
        )
      }})</b>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <i style="margin-left: 20px">{{ translations.tcGideonPresentations }}</i>
                  </b-td>
                  <b-td>
                    <span v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].Video_GID_M }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].Video_GID_Y }}</span>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <i style="margin-left: 20px">{{ translations.tcAuxiliaryPresentations }}</i>
                  </b-td>
                  <b-td>
                    <span v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].Video_AUX_M }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].Video_AUX_Y }}</span>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <b>{{ translations.tcFriends }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="friendcounts.length > 0">{{ friendcounts[0].MonthPrayerPartners +
        friendcounts[0].MonthFinancialPartners
                      }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="friendcounts.length > 0">{{ friendcounts[0].YearPrayerPartners +
        friendcounts[0].YearFinancialPartners
                      }}</b>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <b>{{ translations.tcAuxiliaryHeartProgram }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{
        formatDollars(otherkeymeasures[0].AuxHeartFund_MTD, false)
      }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{
        formatDollars(otherkeymeasures[0].AuxHeartFund_YTD, false)
      }}</b>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <b>{{ translations.tcBirthdayForJesus }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{
        formatDollars(otherkeymeasures[0].BFJ_Receipts_YTD, false)
      }}</b>
                  </b-td>
                  <b-td>
                    <b v-if="otherkeymeasures.length > 0">{{ otherkeymeasures[0].BFJ_Churches_YTD }}</b>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                  <b-td>
                    <span></span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple v-if="filteredyearataglance('S').length > 0" hover small caption-top responsive
              class="table b-table table-striped table-hover table-borderless">
              <b-thead>
                <b-tr>
                  <th class="text-uppercase">{{ translations.tcYearAtAGlance + ' - ' +
        filteredyearataglance('S')[0].SiteName }}</th>
                  <th class>{{ currentMonth() }}</th>
                  <th class>{{ translations.tcYearToDate }}</th>
                  <th class>{{ translations.tcGoalToDate }}</th>
                  <th class>{{ translations.tcVariance }}</th>
                  <th class>{{ translations.tcPercentPlusMinus }}</th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(row, index) in filteredyearataglance('S')" :key="'A' + index">
                  <b-td>
                    <span>{{ row.SiteName }} {{ yagWstypes(row.WSType) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)">{{ formatNumber(row.Period) }}</span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.Period || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.Period) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)">{{ formatNumber(row.YTD) }}</span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.YTD || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.YTD) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)"></span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.GTD || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.GTD) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)"></span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.Variance || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.Variance) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)"></span>
                    <span v-if="['SR', 'SP'].includes(row.WSType)">{{
        formatPercent(row.Pcnt, 100, 2, true)
      }}</span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive
              class="table b-table table-striped table-hover table-borderless" id="yearataglance">
              <b-thead>
                <b-tr>
                  <th class="text-uppercase">{{ translations.tcYearAtAGlance }} - USA</th>
                  <th class>{{ previousMonth() }}</th>
                  <th class>{{ translations.tcYearToDate }}</th>
                  <th class>{{ translations.tcGoalToDate }}</th>
                  <th class>{{ translations.tcVariance }}</th>
                  <th class>{{ translations.tcPercentPlusMinus }}</th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(row, index) in filteredyearataglance('CO')" :key="'C' + index"
                  v-show="filteredyearataglance('S').length > 0">
                  <b-td>
                    <span>{{ row.SiteName }} {{ yagWstypes(row.WSType) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)">{{ formatNumber(row.Period) }}</span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.Period || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.Period) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)">{{ formatNumber(row.YTD) }}</span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.YTD || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.YTD) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)">{{ formatNumber(row.GTD) }}</span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.GTD || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.GTD) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)">{{ formatNumber(row.Variance) }}</span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.Variance || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.Variance) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)"></span>
                    <span v-if="['SR', 'SP'].includes(row.WSType)">{{
        formatPercent(row.Pcnt, 100, 2, true)
      }}</span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive
              class="table b-table table-striped table-hover table-borderless" id="yearataglance">
              <b-thead>
                <b-tr>
                  <th class="text-uppercase">{{ translations.tcYearAtAGlance }} - Worldwide</th>
                  <th class>{{ previousMonth() }}</th>
                  <th class>{{ translations.tcYearToDate }}</th>
                  <th class>{{ translations.tcGoalToDate }}</th>
                  <th class>{{ translations.tcVariance }}</th>
                  <th class>{{ translations.tcPercentPlusMinus }}</th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(row, index) in filteredyearataglance('W')" :key="'B' + index">
                  <b-td>
                    <span>{{ getRegionTextType(row.SiteName) }} {{ yagWstypes(row.WSType) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)">{{ formatNumber(row.Period) }}</span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.Period || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.Period) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)">{{ formatNumber(row.YTD) }}</span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.YTD || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.YTD) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)">{{ formatNumber(row.GTD) }}</span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.GTD || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.GTD) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)">{{ formatNumber(row.Variance) }}</span>
                    <span v-if="['SR', 'BR'].includes(row.WSType)">{{ formatDollars(row.Variance || 0, false) }}</span>
                    <span v-if="row.WSType == 'SP'">{{ formatNumber(row.Variance) }}</span>
                  </b-td>
                  <b-td>
                    <span v-if="['NM', 'AM'].includes(row.WSType)"></span>
                    <span v-if="['SR', 'SP'].includes(row.WSType)">{{
                      formatPercent(row.Pcnt, 100, 2, true)
                      }}</span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </section>
      </page-body>
      <!-- / Page Body -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import date_data from '@/json/date.json'
import iSampleGraph from '@/assets/svgs/sample-graph.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PlacementsChart from '@/components/PlacementsChart.vue'
import ReceiptsChart from '@/components/ReceiptsChart.vue'
import SecurityBanner from '@/components/SecurityBanner'
import SimpleTable from '@/components/simple-table/SimpleTable.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'camp-dashboard',
  mixins: [translationMixin],
  data() {
    return {
      chartdataloaded: false,
      chartplacements: [],
      chartreceipts: [],
      displayrefreshhappening: false,
      friendcounts: [],
      membership: [],
      monthsJson: {},
      otherkeymeasures: [],
      placements: [],
      placementstotals: [],
      pwts: [],
      receipts: [],
      receiptstotals: [],
      translations: {},
      yearataglance: [],
    }
  },
  methods: {
    ...mapActions({
      loadDashboard: 'dashboard/loadDashboard',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    formatDollars(value, plusorminus) {
      let pom = '$'
      if (plusorminus) {
        if (Number(value) < 0) {
          pom = '$-'
        } else {
          pom = '$+'
        }
      }
      return Number(Math.floor(value))
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace('$', pom)
    },
    formatPercent(numerator, denominator, decimals, plusorminus) {
      let pom = ''
      if (plusorminus) {
        pom = (Number(denominator === 0 ? 0 : numerator / denominator) < 0) ? '-' : '+'
      }
      return (
        pom +
        Number(denominator === 0 ? 0 : numerator / denominator).toLocaleString('en-US', {
          style: 'percent',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        })
      ).replace('++', '+').replace('--', '-').replace('+-', '-').replace('-+', '+')
    },
    formatNumber(value) {
      return new Intl.NumberFormat('en-US').format(value)
    },
    currentMonth() {
      const today = new Date()
      return today.toLocaleString(this.prefCulture, { month: 'short' }) + ' ' + today.getFullYear()
    },
    previousMonth() {
      const today = new Date()
      today.setMonth(today.getMonth() - 1)
      return today.toLocaleString(this.prefCulture, { month: 'short' }) + ' ' + today.getFullYear()
    },
    filteredyearataglance(statlevel) {
      let rt = this.yearataglance.filter((item) => {
        return item.Stat_Level == statlevel
      })
      return rt
    },
    filteredplacements(memtype) {
      let rt = []
      if (memtype == 'G') {
        rt = this.placements.filter((item) => {
          return item.TokenValue !== 'Medical' && !item.TokenValue.includes('Auxiliary')
        })
      } else {
        rt = this.placements.filter((item) => {
          return item.TokenValue === 'Medical' || item.TokenValue.includes('Auxiliary')
        })
      }
      return rt
    },
    yagWstypes(value) {
      // Value like: `tcNM`, `tcSR` ...
      const keyName = `tc${value}`
      return this.translations[keyName] || ''
    },
    createTitle(code) {
      // Value like: `st`, `cn`, `xx`
      const keyName = code ? `tc${code.toUpperCase()}` : 'tcXX' // `tcXX` will reference the 'Camp' text
      return this.translations[keyName]
    },
    calcTotal(valname) {
      let total = 0
      switch (valname) {
        case 'ReceiptCampGoal':
          this.receipts.forEach((item) => {
            total += item.CampGoal
          })
          break
        case 'ReceiptCampGoalWithOutBarnabas':
          this.receipts.forEach((item) => {
            if (item.TokenValue !== 'Barnabas Fund') {
              total += item.CampGoal
            }
          })
          break
        case 'ReceiptMonthlyCatTotal':
          this.receipts.forEach((item) => {
            total += item.MonthlyCatTotal
          })
          break
        case 'ReceiptMonthlyCatTotalWithOutBarnabas':
          this.receipts.forEach((item) => {
            if (item.TokenValue !== 'Barnabas Fund') {
              total += item.MonthlyCatTotal
            }
          })
          break
        case 'ReceiptCampActual':
          this.receipts.forEach((item) => {
            total += item.CampActual
          })
          break
        case 'ReceiptCampActualWithOutBarnabas':
          this.receipts.forEach((item) => {
            if (item.TokenValue !== 'Barnabas Fund') {
              total += item.CampActual
            }
          })
          break
        case 'ReceiptMonthlyAmount':
          this.receipts.forEach((item) => {
            total += item.MonthlyAmount
          })
          break
        case 'ReceiptMonthlyAmountWithOutBarnabas':
          this.receipts.forEach((item) => {
            if (item.TokenValue !== 'Barnabas Fund') {
              total += item.MonthlyAmount
            }
          })
          break
        case 'PlacementsCampGoalGid':
          this.filteredplacements('G').forEach((item) => {
            total += item.CampGoal
          })
          break
        case 'PlacementsMonthlyCatTotalGid':
          this.filteredplacements('G').forEach((item) => {
            total += item.MonthlyCatTotal
          })
          break
        case 'PlacementsCampActualGid':
          this.filteredplacements('G').forEach((item) => {
            total += item.CampActual
          })
          break
        case 'PlacementsMonthlyAmountGid':
          this.filteredplacements('G').forEach((item) => {
            total += item.MonthlyAmount
          })
          break
        case 'PlacementsCampGoalAux':
          this.filteredplacements('A').forEach((item) => {
            total += item.CampGoal
          })
          break
        case 'PlacementsMonthlyCatTotalAux':
          this.filteredplacements('A').forEach((item) => {
            total += item.MonthlyCatTotal
          })
          break
        case 'PlacementsCampActualAux':
          this.filteredplacements('A').forEach((item) => {
            total += item.CampActual
          })
          break
        case 'PlacementsMonthlyAmountAux':
          this.filteredplacements('A').forEach((item) => {
            total += item.MonthlyAmount
          })
          break
        case 'PlacementsCampGoal':
          this.placements.forEach((item) => {
            total += item.CampGoal
          })
          break
        case 'PlacementsMonthlyCatTotal':
          this.placements.forEach((item) => {
            total += item.MonthlyCatTotal
          })
          break
        case 'PlacementsCampActual':
          this.placements.forEach((item) => {
            total += item.CampActual
          })
          break
        case 'PlacementsMonthlyAmount':
          this.placements.forEach((item) => {
            total += item.MonthlyAmount
          })
          break
        case 'PwtsMonthlyAmount':
          this.pwts.forEach((item) => {
            total += item.MonthlyAmount
          })
          break
        case 'PwtsCampActual':
          this.pwts.forEach((item) => {
            total += item.CampActual
          })
          break
        case 'PwtsMonthlyCatTotal':
          this.pwts.forEach((item) => {
            total += item.MonthlyCatTotal
          })
          break
        case 'PwtsCampGoal':
          this.pwts.forEach((item) => {
            total += item.CampGoal
          })
          break
      }
      return total
    },
    getRegionTextType(givenText) {
      return givenText.toLowerCase() === 'world' ? this.translations.tcWorld : givenText
    },
    getRowTextType(givenText, givenGuid, nodeName) {
      return this.translations.common[nodeName][givenGuid] ? this.translations.common[nodeName][givenGuid] : givenText
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        let result = false
        await Promise.all([(result = await this.loadDashboard())]).then(() => {
          if (result) {
            if (this.receiptsdata.length == 0) {
              this.displayrefreshhappening = true
            } else {
              this.membership = this.membershipdata
              this.otherkeymeasures = this.otherkeymeasuresdata
              this.yearataglance = this.yearataglancedata
              this.receiptstotals = this.receiptstotalsdata
              this.receipts = this.receiptsdata
              this.placementstotals = this.placementstotalsdata
              this.placements = this.placementsdata
              this.pwts = this.pwtsdata
              this.chartreceipts = this.chartreceiptsdata
              this.chartplacements = this.chartplacementsdata
              this.friendcounts = this.friendcountsdata
              this.translateChartData()
              this.computeMonthlyReceiptAmounts()
              this.computeYTDReceiptAmounts()
            }
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    translateChartData() {
      this.monthsJson = date_data.months
      const chartDataTypes = ['chartplacements', 'chartreceipts']
      chartDataTypes.forEach((dataType) => {
        this[dataType] = this[dataType].map((monthData) => {
          const monthGuid = this.getMonthGuid(monthData.MonthPeriod)
          return { ...monthData, MonthPeriod: this.translations.common['months-abbreviations'][monthGuid.guidAbbr] }
        })
      })
      this.chartdataloaded = true
    },
    computeMonthlyReceiptAmounts() {
      this.receipts.forEach(receipt => {
        if (!!receipt.Details[0]) {
          receipt.MonthlyAmount = receipt.Details.map(d => d.data.month).reduce((accumulator, currentValue) => { return accumulator + currentValue }, 0);
        } else {
          return receipt.MonthlyAmount
        }
      })
    },
    computeYTDReceiptAmounts() {
      this.receipts.forEach(receipt => {
        if (!!receipt.Details[0]) {
          receipt.CampActual = receipt.Details.map(d => d.data.ytd).reduce((accumulator, currentValue) => { return accumulator + currentValue }, 0);
        } else {
          return receipt.CampActual
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      chartplacementsdata: 'dashboard/chartplacements',
      chartreceiptsdata: 'dashboard/chartreceipts',
      friendcountsdata: 'dashboard/friendcounts',
      isInMenu: 'menu/isInMenu',
      membershipdata: 'dashboard/membershipbreakdown',
      otherkeymeasuresdata: 'dashboard/otherkeymeasures',
      placementsdata: 'dashboard/placements',
      placementstotalsdata: 'dashboard/placementstotals',
      prefCulture: 'user/userPreferredCulture',
      pwtsdata: 'dashboard/pwts',
      receiptsdata: 'dashboard/receipts',
      receiptstotalsdata: 'dashboard/receiptstotals',
      yearataglancedata: 'dashboard/yearataglance',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path.substr(0, 29))
    },
    member_data() {
      return !!this.membership && this.membership.length > 0 ?
        [{ nbr0: this.translations.tcTotals, nbr1: this.membership[0].MbrGid_Totals, nbr2: this.membership[0].MbrAux_Totals },
        { nbr0: this.translations.tcNewMembers, nbr1: this.membership[0].MbrGid_New, nbr2: this.membership[0].MbrAux_New },
        { nbr0: this.translations.tcAnnualDinner, nbr1: this.membership[0].MbrGid_Dinner, nbr2: this.membership[0].MbrAux_Dinner },
        { nbr0: this.translations.tcPersonal, nbr1: this.membership[0].MbrGid_Personal, nbr2: this.membership[0].MbrAux_Personal }] : []
    },
    title() {
      const fourth = this.$route.path.split('/')[4]
      return this.createTitle(fourth === 'menu' ? 'xx' : fourth)
    },
  },
  created() {
    try {
      this.setLoadingStatus(true)
      Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('camp-select', 'placements-chart', 'receipts-chart', 'security-banner'),
        this.getComponentTranslations(
          'common.months-abbreviations',
          'common.scripture-placements',
          'common.scripture-receipts'
        ),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    campSelector: CampSelect,
    iSampleGraph: iSampleGraph,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PlacementsChart: PlacementsChart,
    ReceiptsChart: ReceiptsChart,
    securityBanner: SecurityBanner,
    SimpleTable: SimpleTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.camp-dashboard {
  .section-1 {
    margin-top: 35px;
    margin-bottom: 40px;
    padding: 40px;

    @include breakpoint(sm) {
      .d-flex {
        flex-wrap: wrap;
      }
    }

    .graph-block {
      font-size: 36px;
      color: #000;
      margin-right: 45px;
      width: calc(33.333% - 45px);
      text-align: center;

      @include breakpoint(sm) {
        margin-right: 0;
        width: 100%;
        margin-bottom: 45px;
      }

      &:last-of-type {
        margin-right: 0;

        @include breakpoint(sm) {
          margin-bottom: 0;
        }
      }

      h3 {
        text-align: center;
        margin-bottom: 30px;
      }

      svg {
        max-width: 100%;
      }
    }
  }

  .section-2 {
    padding: 40px 30px;
    margin-bottom: 80px;

    h3 {
      color: #000;
      font-size: 42px;
      line-height: 44px;
      margin-bottom: 35px;
    }
  }

  .chart-placements-container {
    width: 100%;
  }

  .chart-receipts-container {
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.month-width {
  width: 113px;
}

.actual-ytd-width {
  width: 174px;
}

.simple-table {
  table {
    tr {
      &.table-total-row {
        font-weight: bold;
      }

      &:nth-of-type(odd) {
        background-color: #fff;
      }

      &:nth-of-type(even) {
        background-color: #f1f1f1;
      }

      &.table-indented-row {
        td:first-of-type {
          padding-left: 30px;
          width: 380px;
        }
      }
    }

    th {
      background-color: #003b48;
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1px;
      text-align: right;

      &:first-of-type {
        text-align: left;
        width: 380px;
      }

      border: 3px solid white;
      padding: 0px 3px;
    }

    td {
      font-size: 18px;
      color: #636363;
      text-align: right;

      &:first-of-type {
        text-align: left;
        width: 380px;
      }
    }
  }
}
</style>
