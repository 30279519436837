<script>
/* eslint-disable */
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  name: 'ReceiptsChart',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'receipts-chart': {
            tcActual: 'Actual',
            tcGoal: 'Goal',
            tcReceipts: 'Receipts',
          },
        }
      },
    },
    data: {},
  },
  methods: {
    renderReceiptsChart() {
      let chartData = {
        labels: [],
        datasets: [
          {
            label: this.i18n['receipts-chart'].tcActual,
            backgroundColor: '#003a47',
            data: [],
            order: 1,
          },
          {
            label: this.i18n['receipts-chart'].tcGoal,
            backgroundColor: '#fbb040',
            data: [],
            order: 2,
          },
          {
            label: '',
            strokeStyle: '#ffffff',
            backgroundColor: '#fbb040',
            data: [],
            type: 'line',
            fill: false,
            borderColor: '#fbb040',
            order: 0,
            pointRadius: 0,
          },
        ],
      }

      chartData.labels = this.data.map((lb) => lb.MonthPeriod)
      chartData.datasets.map((ds, ix) => {
        ds.data = ix == 0 ? this.data.map((lb) => lb.CampActual) : this.data.map((lb) => lb.ReportingGoal)
      })

      this.renderChart(chartData, {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 5,
        legend: {
          display: true,
          align: 'end',
          labels: {
            filter: (item, chart) => {
              // Logic to remove a particular legend item goes here
              return !item.text == ''
            },
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: false,
              beginAtZero: true,
              ticks: {
                // Include a dollar sign in the ticks
                callback: (value, index, values) => {
                  return value / 10000
                },
              },
              scaleLabel: {
                display: true,
                labelString: '* $10,000',
              },
            },
          ],
        },
        title: {
          display: true,
          text: this.i18n['receipts-chart'].tcReceipts,
          fontFamily: "'DIN 1451 W01 Engschrift', sans-serif",
          fontSize: 40,
          fontStyle: 'normal',
          fontColor: '#000000',
          lineHeight: 1.2,
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 0,
            bottom: 0,
          },
        },
      })
    }
  },
  watch: {
    data: function() {
      this.renderReceiptsChart()
    }
  },
  mounted () {
    this.renderReceiptsChart()
  },
}
</script>
<style scoped>
canvas {
  width: 100% !important;
  height: 300px !important;
}
</style>
